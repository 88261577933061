<template>
  <div>
    <b-card>
      <ValidationObserver
        ref="createBannerForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form @submit.prevent="handleSubmit(onClickBannerCreate)">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Select Tenants"
                label-for="tenant"
                class="required"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Tenant"
                  tag="div"
                  class="w-100"
                >
                  <v-select
                    v-model="form.tenant_ids"
                    label="name"
                    :reduce="(option) => option.id"
                    multiple
                    deselect-from-dropdown
                    placeholder="Select Tenant"
                    :options="tenants"
                    @input="getRoleListAndMerchantListForDropdown"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        :required="!form.tenant_ids"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                  <span
                    v-if="errors.length"
                    class="text-danger"
                  >{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Banner Subject (Optional)"
              >
                <b-form-input
                  id="subject_body"
                  ref="subject_content"
                  v-model="form.subject_body"
                  placeholder="Write Banner Subject"
                  rows="3"
                  :state="form.subject_body.length < subjectBodyMaxChar"
                  class="char-textarea"
                  :class="form.subject_body.length >= subjectBodyMaxChar ? 'text-danger' : ''"
                  :maxlength="subjectBodyMaxChar"
                  no-resize
                  @focus="handleOnFocus"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="form.subject_body.length >= subjectBodyMaxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ form.subject_body.length }}</span> / {{ subjectBodyMaxChar }}
                </small>
                <feather-icon
                  v-b-tooltip.hover.v-primary="'Click to view user guide'"
                  icon="AlertCircleIcon"
                  size="15"
                  class="cursor-pointer text-primary"
                />
                <small class="textarea-counter-massage-tooltip">
                  The maximum character count for subject is 100.
                </small>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-group label="Banner Content">
                <div class="example">
                  <quill-editor
                    id="content_body"
                    ref="content_body"
                    v-model="form.content_body"
                    class="editor"
                    :options="editorOption"
                    :maxlength="subjectBodyMaxChar"
                    placeholder="Write Banner Subject"
                    :state="form.content_body.length <= contentBodyMaxChar"
                    :class="{ 'text-danger': filteredContentBodyLength >= contentBodyMaxChar }"
                    no-resize
                    :required="form.is_content_active"
                    @keydown.native="handleKeyDown"
                  />
                  <quillEmoji />
                  <small
                    class="textarea-counter-value float-right"
                    :class="{ 'bg-danger': filteredContentBodyLength >= contentBodyMaxChar }"
                  >
                    <span class="char-count">{{ filteredContentBodyLength }}</span> / {{ contentBodyMaxChar }}
                  </small>
                  <feather-icon
                    v-b-tooltip.hover.v-primary="'Click to view user guide'"
                    icon="AlertCircleIcon"
                    size="15"
                    class="cursor-pointer text-primary"
                  />
                  <small class="textarea-counter-message-tooltip">
                    The maximum character count for content is 170.
                  </small>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            id="selected-options"
            :class="{ disabled: isDisabled }"
          >
            <b-row>
              <b-col md-="6">
                <b-form-group
                  label="Show in Login Page"
                >
                  <div class=" d-flex flex-wrap mt-1">
                    <b-form-radio-group
                      v-model="form.is_login_enable"
                      :options="radioOptions"
                      class="custom-control-secondary"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                      @change="clearNotificationParties()"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group>
                  <b-form-group
                    label="Notification Parties"
                  >
                    <div class="d-flex flex-wrap mt-1">
                      <b-form-checkbox
                        v-model="form.is_staff_active"
                        :value="true"
                        class="custom-control-secondary mr-2"
                        @change="handleStaffChange"
                      >
                        Staff
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="form.is_merchant_active"
                        :value="true"
                        class="custom-control-secondary"
                        @change="handleMerchantChange"
                      >
                        Merchant
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </b-form-group></b-col>
              <b-col
                v-show="form.is_staff_active && !form.is_login_enable"
                cols="6"
                md="6"
              >
                <b-form-group
                  label="Applicable Staff Roles"
                >
                  <v-select
                    v-model="form.user_roles['staff-api']"
                    :options="roles"
                    :reduce="option => option.id"
                    multiple
                    deselect-from-dropdown
                    label="name"
                    placeholder="Select Role"
                    :disabled="!form.is_staff_active"
                    @input="onStaffChange"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col
                v-show="form.is_merchant_active && !form.is_login_enable"
                cols="6"
                md="6"
              >
                <b-form-group
                  label="Merchants"
                >
                  <v-select
                    v-model="form.merchant_ids"
                    :options="merchantOptions"
                    :reduce="option => option.id"
                    multiple
                    deselect-from-dropdown
                    label="name"
                    placeholder="Select Merchants"
                    :disabled="!form.is_merchant_active"
                    @input="onMerchantChange"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class=" mt-1">
              <b-col
                cols="6"
              >
                <b-form-group>

                  <b-form-group
                    label="Status Type"
                  >

                    <div class="d-flex flex-wrap mt-1">
                      <b-form-checkbox
                        v-model="form.is_active"
                        :value="true"
                        class="custom-control-secondary mr-2"
                      >
                        Active
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Select Banner Color"
                >
                  <v-select
                    v-model="form.color_ids"
                    :options="colors"
                    :reduce="option => option.color"
                    deselect-from-dropdown
                    label="name"
                    placeholder="Select Banner Color"
                    :disabled="!form.is_staff_active && !form.is_merchant_active"
                    @input="onColorChange"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        :required="!form.color_ids || form.color_ids.length <= 0"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Select an Expiry Date"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="expiredDate"
                    vid="expiredDate"
                    rules="required-date"
                  >
                    <SingleAdvancedDatePicker
                      v-model="form.dateNtime"
                      :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD HH:MM' }"
                      :single-date-picker="true"
                      :date-format="'YYYY-MM-DD HH:mm'"
                      :min-date="currentDate"
                      :clear-icon="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class=" mt-1">
              <b-col
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="clearForm"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </ValidationObserver>
      <b-row class="mt-4">
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Tenant"
            label-for="tenants"
          >
            <v-select
              v-model="filters['tenant_ids']"
              :options="tenants"
              :reduce="option => option.id"
              placeholder="Select Tenant"
              label="name"
            >
              <template
                v-slot:option="option"
                style="height: 200px"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="filters['is_active']"
              :options="statusOptions"
              :reduce="option => option.key"
              placeholder="Select Status"
              label="name"
            >
              <template
                v-slot:option="option"
                style="height: 200px"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="4"
          sm="8"
          class="my-1"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  -- Sort --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :fields="fields"
          :items="rows"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
        >
          <template #cell(tenant)="data">
            {{ getTenantName(data.value) || '---' }}
          </template>
          <template #cell(subject)="data">
            <div class="content-body-cell">
              {{ data.value || '---' }}
            </div>
          </template>
          <template #cell(message)="data">
            <div
              class="content-body-cell"
              v-html="data.value"
            />
          </template>
          <!--Staff Status-->
          <template #cell(staff_portal)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                On
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-danger">
                Off
              </b-badge>
            </div>
          </template>
          <!--Merchant Status-->
          <template #cell(merchant_portal)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                On
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-danger">
                Off
              </b-badge>
            </div>
          </template>
          <template #cell(status)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                Active
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-danger">
                Inactive
              </b-badge>
            </div>
          </template>
          <template #cell(color)="data">
            {{ getColorName(data.value) }}
          </template>
          <template #cell(created_date)="data">
            {{ __dateTimeFormatter(data.value) || '---' }}
          </template>
          <template #cell(expiry_date)="data">
            {{ data.value || '---' }}
          </template>
          <template #cell(is_admin_banner)="data">
            <div v-if="data.value">
              <b-badge variant="light-success">
                Admin
              </b-badge>
            </div>
            <div v-if="!data.value">
              <b-badge variant="light-primary">
                Staff
              </b-badge>
            </div>
          </template>
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="10"
                  class="text-body align-middle "
                />
              </template>
              <b-dropdown-item
                v-can="'edit-status-notification'"
                @click="onClickEditBanner(data.item.id)"
              >
                <feather-icon icon="Edit3Icon" />
                Edit
              </b-dropdown-item>
              <b-dropdown-item
                v-can="'edit-status-notification'"
                @click="onClickDelete(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>

      <b-row class="mt-2">
        <b-col
          class="d-flex align-items-center justify-content-sm-start"
          md="3"
        >
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-col>
        <b-col
          md="9"
          class="d-flex align-items-center justify-content-end"
        >
          <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
          <b-pagination
            v-model="page"
            :per-page="perPage"
            :total-rows="(meta.total)? meta.total : 0"
            class="mb-0 mt-1 mt-sm-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <BannerNotificationTable ref="editBannerModal" />
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import BannerNotificationTable from '@/components/Alerts/BannerNotificationTable.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import vSelect from 'vue-select'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import SingleAdvancedDatePicker from '@/components/AdvancedDateFilter/SingleAdvancedDatePicker.vue'
import moment from 'moment'
import Filter from '@core/mixins/filter'

const BannerRepository = RepositoryFactory.get('banner')
const MerchantRepository = RepositoryFactory.get('merchant')
const ClientRepository = RepositoryFactory.get('client')
const RoleRepository = RepositoryFactory.get('role')

export default {
  components: {
    BannerNotificationTable,
    BCard,
    BPagination,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormRadioGroup,
    quillEditor,
    quillEmoji,
    SingleAdvancedDatePicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      subjectBodyMaxChar: 100,
      contentBodyMaxChar: 170,
      editorOption: {
        theme: 'snow',
        modules: {
          'emoji-toolbar': true,
          'emoji-shortname': true,
          toolbar: [
            ['bold', 'italic', 'underline', 'link', 'emoji'],
            [{ script: 'sub' }, { script: 'super' }],
          ],
        },
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      loading: false,
      filters: {},
      filter: null,
      merchantOptions: [],
      roles: [],
      rows: [],
      meta: {},
      page: 1,
      perPage: 10,
      pageOptions: [10, 20, 30],
      colors: [
        { name: 'Red', color: '#B93160' },
        { name: 'Yellow', color: '#E7AB79' },
        { name: 'Green', color: '#5FD068' },
        { name: 'Blue', color: '#4688f5' },
      ],
      form: {
        is_merchant_active: false,
        is_staff_active: false,
        color_ids: [],
        dateNtime: {
          startDate: null,
          endDate: null,
        },
        merchant_ids: [],
        subject_body: '',
        content_body: '',
        is_login_enable: false,
        is_active: false,
        tenant_ids: [],
        user_roles: {
          'staff-api': [],
        },
        expiray_date: '',
      },
      selectedTextfieldId: '',
      tenants: [],
      statusOptions: [
        {
          key: '1',
          name: 'Active',
        },
        {
          key: '0',
          name: 'Inactive',
        },
      ],
      radioOptions: [
        { text: 'Enable', value: true },
        { text: 'Disable', value: false },
      ],
      fields: [
        { key: 'tenant', label: 'Tenant', sortable: false },
        { key: 'subject', label: 'Subject', sortable: false },
        {
          key: 'message',
          label: 'Content',
          sortable: false,
        },
        { key: 'staff_portal', label: 'Staff', sortable: false },
        { key: 'merchant_portal', label: 'Merchant', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'created_date', label: 'Created Date', sortable: true },
        { key: 'expiry_date', label: 'Expiry Date', sortable: true },
        { key: 'color', label: 'Color', sortable: false },
        { key: 'is_admin_banner', label: 'Admin / Staff', sortable: false },
        { key: 'action', label: 'Action', sortable: false },
      ],
      isDisabled: true,
    }
  },
  computed: {
    filteredContentBodyLength() {
      const strippedContent = this.form.content_body.replace(/(<([^>]+)>)/gi, '')
      return strippedContent.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    formattedExpiryDate() {
      if (this.form.dateNtime && this.form.dateNtime.startDate) {
        return moment(this.form.dateNtime.startDate).format('YYYY-MM-DD HH:mm')
      }
      return ''
    },
    currentDate() {
      return moment().format('YYYY-MM-DD HH:mm')
    },
  },
  watch: {
    options: {
      handler() {
        this.getAllBannerList()
      },
    },
    page() {
      this.getAllBannerList()
    },
    perPage() {
      this.getAllBannerList()
    },
  },
  mounted() {
    this.getClientListForDropdown()
    this.getAllBannerList()
  },
  methods: {
    handleKeyDown(event) {
      const { keyCode } = event
      const isBackspace = keyCode === 8
      if (this.filteredContentBodyLength >= this.contentBodyMaxChar && !isBackspace) {
        event.preventDefault() // Prevent typing when character count exceeds the limit
      }
    },
    async getClientListForDropdown() {
      this.loading = true
      try {
        const { data } = await ClientRepository.getClientListForDropdown()
        this.tenants = data.data
        this.tenants.unshift({
          id: -1,
          name: 'All',
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getRoleListAndMerchantListForDropdown() {
      this.isDisabled = false
      if (this.form.tenant_ids.includes(-1)) {
        this.form.tenant_ids = [-1]
        this.form.user_roles['staff-api'] = []
        this.form.merchant_ids = []
      }

      if (this.form.tenant_ids.length === 0) {
        this.isDisabled = true
      }
      await this.getRoleListForDropdown()
    },
    async getRoleListForDropdown() {
      this.loading = true
      try {
        this.roles = []
        if (!this.form.tenant_ids.includes(-1)) {
          const { data } = await RoleRepository.getStaffRoleListforDropdown(
            this.form.tenant_ids,
          )
          this.roles = data.data
        } else {
          this.form.user_roles['staff-api'] = [-1]
        }
        this.roles.unshift({
          id: -1,
          name: 'All',
        })
        this.getMerchantListForDropdown()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getMerchantListForDropdown() {
      this.loading = true
      try {
        this.merchantOptions = []
        if (!this.form.tenant_ids.includes(-1)) {
          const { data } = await MerchantRepository.getMerchantListforDropdown(
            this.form.tenant_ids,
          )
          this.merchantOptions = data.data
        } else {
          this.form.merchant_ids = [-1]
        }
        this.merchantOptions.unshift({
          id: -1,
          name: 'All',
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getAllBannerList() {
      try {
        this.loading = true
        const { data } = (await BannerRepository.getAllBannerList(this.filterQuery, this.page, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchBannerListNoPagination() {
      try {
        const { data } = (await BannerRepository.getAllBannerListNoPagination(this.sort, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    removeHtmlTags(input) {
      const doc = new DOMParser().parseFromString(input, 'text/html')
      return doc.body.textContent || ''
    },
    async onClickBannerCreate() {
      try {
        this.loading = true
        const payload = {
          is_staff_active: this.form.is_staff_active,
          is_merchant_active: this.form.is_merchant_active,
          notification_body: this.form.content_body,
          subject: this.form.subject_body,
          user_roles: JSON.parse(JSON.stringify(this.form.user_roles)),
          merchant_ids: this.form.merchant_ids,
          tenant_ids: this.form.tenant_ids,
          is_active: this.form.is_active,
          is_login_enable: this.form.is_login_enable,
          banner_data: {
            color: this.form.color_ids,
            expiray_date: this.formattedExpiryDate,
          },
        }

        if (
          payload.user_roles['staff-api'].filter(role => role === 'all')
            .length > 0
        ) {
          payload.user_roles = 'all'
        }

        if (this.filteredContentBodyLength > this.contentBodyMaxChar) {
          this.showErrorMessage('Banner content has exceeded the maximum character limit')
          return
        }

        const res = await BannerRepository.createBanner(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Alert broadcasted successfully')
          this.clearForm()
        } else {
          this.showErrorMessage('Failed to send alert')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearForm() {
      this.form.is_merchant_active = false
      this.form.is_staff_active = false
      this.form.is_active = false
      this.form.is_login_enable = false
      this.form.merchant_ids = []
      this.form.dateNtime = {
        startDate: null,
        endDate: null,
      }
      this.form.color_ids = []
      this.form.subject_body = ''
      this.form.content_body = ''
      this.form.tenant_ids = []
      this.form.user_roles = {
        'staff-api': [],
      }
      this.getAllBannerList()
    },
    handleChangePage(page) {
      this.page = page
      this.getAllBannerList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickDelete(id) {
      this.boxDelete = ''
      this.$bvModal
        .msgBoxConfirm('Do you really want to delete these record?.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onClickConfirm(id)
          }
        })
    },
    async onClickConfirm(id) {
      try {
        const res = await BannerRepository.deleteBanner(id)
        if (res.status === 200) {
          this.showSuccessMessage('Banner Data Deleted successfully')
          await this.getAllBannerList()
        } else {
          this.showErrorMessage("Couldn't Delete Banner Data")
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onMerchantChange() {
      if (this.form.merchant_ids.includes(-1)) this.form.merchant_ids = [-1]
    },
    onStaffChange() {
      if (this.form.user_roles['staff-api'].includes(-1)) this.form.user_roles['staff-api'] = [-1]
    },
    handleOnFocus(e) {
      this.selectedTextfieldId = e.target.id
    },
    onClickEditBanner(id) {
      this.$refs.editBannerModal.openModal(id)
    },
    handleStaffChange() {
      if (this.form.is_staff_active) {
        this.form.user_roles['staff-api'] = [-1]
      } else {
        this.form.user_roles['staff-api'] = []
      }
    },
    handleMerchantChange() {
      if (this.form.is_merchant_active) {
        this.form.merchant_ids = [-1]
      } else {
        this.form.merchant_ids = []
      }
    },
    clearNotificationParties() {
      if (this.form.is_login_enable) {
        this.form.is_staff_active = false
        this.form.is_merchant_active = false
      } else {
        this.form.is_staff_active = false
        this.form.is_merchant_active = false
        this.form.user_roles['staff-api'] = []
        this.form.merchant_ids = []
      }
    },
    getColorName(colorCode) {
      const color = this.colors.find(n => n.color === colorCode)
      return color ? color.name : '---'
    },
    getTenantName(tenantIdsString) {
      const tenantIds = JSON.parse(tenantIdsString)

      // If tenantIds is not an array, convert it to an array containing the single ID
      const ids = Array.isArray(tenantIds) ? tenantIds : [tenantIds]

      return ids.map(id => {
        const tenant = this.tenants.find(n => n.id === id)
        return tenant ? tenant.name : 'Unknown Tenant'
      }).join(', ')
    },
    filterQueryUpdate() {
      this.getAllBannerList()
    },
  },
}
</script>

<style lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
@import '@core/scss/vue/required.scss';
</style>
